import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

import { RealtimeProviders } from './layout/providers';
import { SimpleSidebarLayout } from 'src/components/Layout';
import { RealtimePage } from './layout';

import { REALTIMEDISPLAY } from 'src/app/featureToggles';
import { useConfigSelectors } from 'src/app/reducers';

export const RealtimeScreen = () => {
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_REALTIMEDISPLAY = configSelectors.isFeatureAvailable(REALTIMEDISPLAY);

  if (!FEATURE_FLAG_REALTIMEDISPLAY) {
    return <p>Feature not enabled</p>;
  }

  return (
    <RealtimeProviders>
      <SimpleSidebarLayout>
        <RealtimePage />
      </SimpleSidebarLayout>
    </RealtimeProviders>
  );
};

export const Realtime = withRouteProps(RealtimeScreen);
