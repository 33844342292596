import { useEffect, useRef } from 'react';
import { MapActionType, MapEventConfig, useMapContext } from 'src/@realtime/contexts/map';

export const useAddOnMapClickFunction = (clickFunction: MapEventConfig) => {
  const { dispatch } = useMapContext();
  const prevClickFunctionRef = useRef<MapEventConfig | null>(null);

  useEffect(() => {
    // Compare the new clickFunction with the previous one
    if (
      prevClickFunctionRef.current &&
      prevClickFunctionRef.current.id === clickFunction.id &&
      prevClickFunctionRef.current.event === clickFunction.event
    ) {
      return; // Avoid re-triggering if the clickFunction is the same
    }

    // Update the reference and add the click event
    prevClickFunctionRef.current = clickFunction;
    dispatch({ type: MapActionType.ADD_CLICK_EVENT, payload: clickFunction });

    // Cleanup by removing the click event
    return () => {
      dispatch({ type: MapActionType.REMOVE_CLICK_EVENT, payload: clickFunction.id });
    };
  }, [clickFunction, dispatch]);
};
