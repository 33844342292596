import React, { createContext, useContext } from 'react';

// Create a context to store the map reference
interface AuthStoreContextType {
  token: string;
}

const AuthStoreContext = createContext<AuthStoreContextType | null>(null);

// Create a provider component
export const AuthStoreProvider: React.FC<{ token: string; children: React.ReactNode }> = ({
  token,
  children,
}) => {
  // Clean the token if it starts with 'Bearer '
  const cleanedToken = token.startsWith('Bearer ') ? token.slice(7) : token;
  return (
    <AuthStoreContext.Provider value={{ token: cleanedToken }}>
      {children}
    </AuthStoreContext.Provider>
  );
};

// Create a custom hook to use the map context
export const useAuthStoreContext = () => {
  const context = useContext(AuthStoreContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthStoreContext');
  }
  return context;
};
