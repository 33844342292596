import React from 'react';
// Icons
import { Icons } from '@ems/client-design-system';
// screens
// import { Dashboards } from 'src/@dashboard';
import { Operations, OperationsSummary } from 'src/@operations';
import { NoiseEvents, NoiseEventsSummary } from 'src/@noiseEvents';
import {
  Infringements,
  InfringementsSummary,
  InfringementsCandidates,
  InfringementsATC,
} from 'src/@infringements';
import { Reports } from 'src/@reports';
import {
  Settings,
  GeneralSettingsScreen,
  CarbonEmissionsSettingsScreen,
  SpatialFeaturesSettingsScreen,
  InfringementRulesSettingsScreen,
  ChangeLogSettingsScreen,
  ModelingSettingsScreen,
  ModelingAircraftAssignmentsSettingsScreen,
  ContoursSettingsScreen,
  LocationManagementScreen,
  LocationManagementDetailsScreen,
  TaxiPathScreen,
  AircraftMappingsScreen,
} from 'src/@settings';
import { AddFlight, Complaints, NewComplaint, ComplaintDetails } from 'src/@complaints';
import { UserHelp } from 'src/@userHelp';
import { Airtrak } from 'src/@airtrak/screens';
import { CreateNoiseScenarioScreen, ViewNoiseScenarioScreen } from 'src/@scenarioGeneration';
import { NOISE_DEVICE, REALTIME } from 'src/constants';
import { Dashboards } from 'src/@dashboard';
import { Realtime } from 'src/@realtime';

const dashboardIcon = <Icons iconName="ic-overview" className="ic-overview" size="20" />;
const operationsIcon = <Icons iconName="ic-operations" className="ic-operations" size="20" />;
const noiseIcon = <Icons iconName="ic-noise" className="ic-noise" size="20" />;
const infringementsIcon = (
  <Icons iconName="ic-infringements" className="ic-infringements" size="20" />
);
const reportsIcon = <Icons iconName="ic-reports" className="ic-reports" size="20" />;
const complaintsIcon = <Icons iconName="ic-complaints" className="ic-complaints" size="20" />;
const airTrakIcon = <Icons iconName="ic-ui-airtrak" className="ic-ui-airtrak" size="20" />;
const settingsIcon = <Icons iconName="ic-settings" className="ic-settings" size="20" />;
const supportIcon = <Icons iconName="ic-support" className="ic-support" size="20" />;
const scenarioGenerationIcon = (
  <Icons iconName="ic-noise-scenario" className="ic-noise-scenario" size="28" />
);

// 1. Pages with NotFound are not done yet, but are needed for NavBar purposes
export const routes = [
  {
    route: 'dashboards',
    icon: dashboardIcon,
    navBarPosition: 'header',
    isMobile: true, // Hide non-mobile friendly screen links in mobile Navbar
    component: Dashboards,
    permissions: [{ name: 'Dashboard', type: ['Read'] }],
  },
  {
    route: 'operations/:id',
    component: OperationsSummary,
    permissions: [
      { name: 'Operation', type: ['Read'] },
      { name: 'OperationSummary', type: ['Read'] },
    ],
  },
  {
    route: 'infringements/:id',
    component: InfringementsSummary,
    permissions: [
      { name: 'Infringement', type: ['Read'] },
      { name: 'InfringementSummary', type: ['Read'] },
    ],
  },
  {
    route: 'noiseevents/:id',
    component: NoiseEventsSummary,
    permissions: [
      { name: 'NoiseEvent', type: ['Read'] },
      { name: 'NoiseEventSummary', type: ['Read'] },
    ],
  },
  {
    route: 'operations',
    icon: operationsIcon,
    navBarPosition: 'header',
    component: Operations,
    permissions: [
      { name: 'Operation', type: ['Read'] },
      { name: 'OperationSummary', type: ['Read'] },
    ],
  },
  {
    route: 'noiseevents',
    icon: noiseIcon,
    navBarPosition: 'header',
    component: NoiseEvents,
    permissions: [
      { name: 'NoiseEvent', type: ['Read'] },
      { name: 'NoiseEventSummary', type: ['Read'] },
    ],
  },
  {
    route: 'infringements',
    icon: infringementsIcon,
    navBarPosition: 'header',
    component: Infringements,
    permissions: [
      { name: 'Infringement', type: ['Read'] },
      { name: 'InfringementSummary', type: ['Read'] },
    ],
  },
  {
    route: 'infringements-candidates',
    icon: infringementsIcon,
    component: InfringementsCandidates,
    navBarPosition: 'header',
    permissions: [
      { name: 'Infringement', type: ['Read'] },
      { name: 'InfringementSummary', type: ['Read'] },
    ],
  },
  {
    route: 'infringements-atc',
    component: InfringementsATC,
    navBarPosition: 'header',
    icon: infringementsIcon,
    permissions: [
      { name: 'Infringement', type: ['Read'] },
      { name: 'InfringementSummary', type: ['Read'] },
    ],
  },
  {
    route: 'complaints/create',
    component: NewComplaint,
    permissions: [
      { name: 'Complainer', type: ['Read'] },
      { name: 'ComplaintSummary', type: ['Read'] },
    ],
  },
  {
    route: 'complaints/:id/flight',
    component: AddFlight,
    permissions: [
      { name: 'Complainer', type: ['Read'] },
      { name: 'ComplaintSummary', type: ['Read'] },
    ],
  },
  {
    route: 'complaints/:id',
    component: ComplaintDetails,
    permissions: [
      { name: 'Complainer', type: ['Read'] },
      { name: 'ComplaintSummary', type: ['Read'] },
    ],
  },
  {
    route: 'complaints',
    icon: complaintsIcon,
    navBarPosition: 'header',
    component: Complaints,
    permissions: [
      { name: 'Complainer', type: ['Read'] },
      { name: 'ComplaintSummary', type: ['Read'] },
    ],
  },
  {
    route: 'carbonemissions',
    icon: airTrakIcon,
    navBarPosition: 'header',
    component: Airtrak,
    permissions: [{ name: 'EmissionColumns', type: ['Read'] }],
  },
  {
    route: 'reports',
    icon: reportsIcon,
    navBarPosition: 'header',
    component: Reports,
    permissions: [{ name: 'PeriodicReport', type: ['Read'] }],
  },
  {
    route: 'noisemodeling/:id',
    component: ViewNoiseScenarioScreen,
    permissions: [{ name: 'AircraftNoiseModelingScenario', type: ['Insert'] }],
  },
  {
    route: 'noisemodeling',
    icon: scenarioGenerationIcon,
    navBarPosition: 'header',
    component: CreateNoiseScenarioScreen,
    permissions: [{ name: 'AircraftNoiseModelingScenario', type: ['Insert'] }],
  },
  {
    route: 'settings/general',
    component: GeneralSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/infringement-rules/:id',
    component: InfringementRulesSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/infringement-rules',
    component: InfringementRulesSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/spatial-features/:id',
    component: SpatialFeaturesSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/spatial-features',
    component: SpatialFeaturesSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/changelog',
    component: ChangeLogSettingsScreen,
    permissions: [{ name: 'AuditLogEntrySummary', type: ['Read'] }],
  },
  {
    route: 'settings/carbon/aircraft-mappings',
    component: AircraftMappingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/carbon/fleet-mix',
    component: CarbonEmissionsSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/carbon/time-modes',
    component: CarbonEmissionsSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/carbon/load-factors',
    component: CarbonEmissionsSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/carbon/fuel-types',
    component: CarbonEmissionsSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings/carbon',
    component: CarbonEmissionsSettingsScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  /**
   * Contours routes
   */
  {
    route: 'settings/modeling/contours',
    component: ContoursSettingsScreen,
    permissions: [
      {
        name: 'AircraftNoiseContour',
        type: ['Read'],
      },
    ],
  },
  /**
   * Modeling routes
   */
  {
    route: 'settings/modeling/aircraft-assignments/:id',
    component: ModelingAircraftAssignmentsSettingsScreen,
    permissions: [
      {
        name: 'AircraftToNoiseModelingTypeSummary',
        type: ['Read'],
      },
    ],
  },
  {
    route: 'settings/modeling/aircraft-assignments',
    component: ModelingAircraftAssignmentsSettingsScreen,
    permissions: [
      {
        name: 'AircraftToNoiseModelingTypeSummary',
        type: ['Read'],
      },
    ],
  },
  {
    route: 'settings/modeling',
    component: ModelingSettingsScreen,
    permissions: [
      {
        name: 'AircraftToNoiseModelingTypeSummary',
        type: ['Read'],
      },
      {
        name: 'AircraftNoiseModelingScenario',
        type: ['Read'],
      },
    ],
  },

  // Noise management
  {
    route: 'settings/nmt-management/:id',
    component: LocationManagementDetailsScreen,
    permissions: [
      { name: NOISE_DEVICE, type: ['Read'] },
      { name: NOISE_DEVICE, type: ['Update'] },
    ],
  },
  {
    route: 'settings/nmt-management',
    component: LocationManagementScreen,
    permissions: [
      { name: NOISE_DEVICE, type: ['Read'] },
      { name: NOISE_DEVICE, type: ['Update'] },
    ],
  },
  {
    route: 'settings/taxi-path',
    component: TaxiPathScreen,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },
  {
    route: 'settings',
    icon: settingsIcon,
    navBarPosition: 'footer',
    component: Settings,
    redirect: 'settings/general',
    isExternal: true,
    permissions: [{ name: 'Setting', type: ['Read'] }],
  },

  {
    route: 'userHelp',
    icon: supportIcon,
    navBarPosition: 'footer',
    component: UserHelp,
    permissions: [{ name: 'UserHelpItem', type: ['Read'] }],
  },
  {
    route: REALTIME,
    icon: operationsIcon,
    navBarPosition: 'header',
    component: Realtime,
    permissions: [
      { name: 'TrackPoint.RealTime', type: ['Read'] },
      { name: 'NoiseSample.RealTime', type: ['Read'] },
      { name: 'PeriodicWeather.RealTime', type: ['Read'] },
    ],
  },
];
