import styled from 'styled-components/macro';

// Colors
// import styles from 'src/styles/vars.module.scss';

import { Dialog } from '@ems/client-design-system';

export const MapSettingsDialog = styled(Dialog)`
  width: 775px;
`;

export const MapSettingsWrapper = styled.div`
  display: flex;
  min-height: 200px;
  justify-content: space-between;
`;

export const MapSettingsItem = styled.div`
  padding: 0 12px;
  border-right: 1px solid #8897a2;
  width: 215px;

  &:last-child {
    border-right: none;
  }
`;

export const MapSettingsHeader = styled.h3`
  font-size: 1.125rem;
  text-align: center;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
`;
