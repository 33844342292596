import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
// components
import { AppShell } from 'src/app/components/AppShell';
// utils
import { getIdleTimeout, signOut } from 'src/app/functions/core';
// constant
import { USER_LAST_ACTIVE } from 'src/constants';

export const Authenticated = ({ client, token }) => {
  const timerRef = useRef<any>(null);
  const debounce = 3000;

  const appOnIdle = () => {
    const logout = () => {
      console.log(`Logged out automatically due to no activity.`);
      signOut('001');
    };
    const currentTime = new Date().getTime();
    const lastActiveUser = window.localStorage.getItem(USER_LAST_ACTIVE);
    if (lastActiveUser) {
      const timePassedSinceLastActivity = currentTime - Number(lastActiveUser);
      if (timePassedSinceLastActivity > getIdleTimeout()) {
        console.log(
          'timePassedSinceLastActivity:',
          timePassedSinceLastActivity,
          'idleTimeout:',
          getIdleTimeout()
        );
        window.localStorage.removeItem(USER_LAST_ACTIVE);
        logout();
      } else {
        timerRef.current.reset();
      }
    } else {
      console.log('last active user missing.');
      logout();
    }
  };

  const appOnAction = () => {
    if (timerRef && timerRef.current) {
      window.localStorage.setItem(USER_LAST_ACTIVE, timerRef.current.getLastActiveTime());
    }
  };
  // TODO - replace idle timer with updated useIdleTimerHook
  return (
    <>
      {/* @ts-ignore  ts error TS2786 occurred when upgrading typescript*/}
      <IdleTimer
        ref={timerRef}
        onIdle={appOnIdle}
        debounce={debounce}
        timeout={getIdleTimeout()}
        onAction={appOnAction}
      />
      <AppShell client={client} token={token} />
    </>
  );
};
