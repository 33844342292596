import { FlightDataAction, FlightDataActionType, FlightDataState } from './types';
import { interpolateMissingPoints } from 'src/@realtime/utils';

export const flightReducer = (
  state: FlightDataState,
  action: FlightDataAction
): FlightDataState => {
  switch (action.type) {
    case FlightDataActionType.ADD_FLIGHT: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        operationType,
        operationColor,
        point: { trackId, time, latitude, longitude, altitude, ...staticData },
      } = action.payload;

      // Only add static info if this trackId is new
      if (!state.flightInfo[trackId]) {
        return {
          ...state,
          flightInfo: {
            ...state.flightInfo,
            [trackId]: {
              ...staticData,
              operationType,
              operationColor,
            },
          },
        };
      }
      return state;
    }
    case FlightDataActionType.ADD_POSITION: {
      const { trackPoint, time } = action.payload;
      const { trackId } = trackPoint;
      const { positions } = state;
      // Check if the trackId exists
      if (!positions[trackId]) {
        return {
          ...state,
          positions: {
            ...state.positions,
            [trackPoint.trackId]: [
              {
                time,
                latitude: trackPoint.latitude,
                longitude: trackPoint.longitude,
                altitude: trackPoint.altitude,
                heading: 0,
              },
            ],
          },
        };
      }

      // Check if the position already exists
      if (positions[trackPoint.trackId].some(position => position.time === time)) {
        return state;
      } else {
        const previousPoint = positions[trackId]
          .slice()
          .reverse()
          .find(point => point.time < time);

        if (previousPoint) {
          const pointsToDispatch = interpolateMissingPoints(previousPoint, {
            time,
            latitude: trackPoint.latitude,
            longitude: trackPoint.longitude,
            altitude: trackPoint.altitude,
            heading: 0,
          });
          return {
            ...state,
            positions: {
              ...state.positions,
              [trackId]: [...state.positions[trackId], ...pointsToDispatch],
            },
          };
        }
      }

      return state;
    }
    case FlightDataActionType.ADD_POSITIONS: {
      const { trackId, positions } = action.payload;
      const positionsPayload = Array.isArray(positions) ? positions : [positions];

      const updatedPositions = { ...state.positions };

      // Add each position to the respective trackId
      for (const positionData of positionsPayload) {
        const { time, latitude, longitude, altitude, heading } = positionData;

        // Initialize the positions array if it doesn't exist
        if (!updatedPositions[trackId]) {
          updatedPositions[trackId] = [];
        }

        // Check if a point with the same timestamp already exists
        const existingIndex = updatedPositions[trackId].findIndex(pos => pos.time === time);

        // Append the new position data if it doesn't exist already
        if (existingIndex === -1) {
          updatedPositions[trackId].push({
            time: new Date(time).getTime(),
            latitude,
            longitude,
            altitude,
            heading,
          });
        }
      }

      return {
        ...state,
        positions: updatedPositions,
      };
    }
    case FlightDataActionType.SELECT_TRACK: {
      const trackId = action.payload;
      const { selectedTracks } = state;

      // Toggle the trackId in the selectedTracks array
      const updatedSelectedTracks = selectedTracks.includes(trackId)
        ? selectedTracks.filter(id => id !== trackId)
        : [...selectedTracks, trackId];

      return {
        ...state,
        selectedTracks: updatedSelectedTracks,
      };
    }
    default:
      return state;
  }
};
