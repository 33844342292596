import { LineLayer } from 'react-map-gl';
import { FlightTailLayerIds } from './constants';

export const flightTailLayerStyle: LineLayer = {
  id: FlightTailLayerIds.flightTailLayer,
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': ['get', 'operationColor'],
    'line-width': 2,
  },
};
