import React, { useEffect } from 'react';
import { useMapConfigsWrapper, useLoadLiveData } from 'src/@realtime/hooks';
import { DebugPanel, FlightLayer, Map, MapControls } from 'src/@realtime/components';
import { useTimeProgression } from 'src/@realtime/hooks/playback';
import { PlaybackActionType, PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';
import { calculateMaxBounds } from 'src/@realtime/utils';
import { DEFAULT_BBOX_DISTANCE } from 'src/constants';
import { FlightTailLayer } from 'src/@realtime/components/flightTailLayer';

export const RealtimePage: React.FC = () => {
  const {
    centre: { latitude, longitude },
    zoom,
    mapAccessToken,
    maxZoom,
    minZoom,
  } = useMapConfigsWrapper();
  const {
    state: { timestamps, playbackStatus },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  useLoadLiveData();
  useTimeProgression();

  // Start playback if we have enough data
  useEffect(() => {
    if (timestamps.length > 40 && playbackStatus === PlaybackStatus.Buffering) {
      playbackDispatch({
        type: PlaybackActionType.SET_PLAYBACK_STATUS,
        payload: PlaybackStatus.Playing,
      });
    }
  }, [timestamps, playbackDispatch]);
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Map
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        apiKey={mapAccessToken}
        maxZoom={maxZoom}
        minZoom={minZoom}
        maxBounds={calculateMaxBounds(latitude, longitude, DEFAULT_BBOX_DISTANCE)}>
        <MapControls resetView mapSettings />
        <DebugPanel />
        <FlightTailLayer />
        <FlightLayer />
      </Map>
    </div>
  );
};
