import { SymbolLayer } from 'react-map-gl';
import { FlightLayerIds } from './constants';
// import { InteractiveLayerId } from 'src/components/Map/hooks/useInteractiveLayerIds';

export const flightLayerStyle: SymbolLayer = {
  id: FlightLayerIds.flightLayer,
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': 0.8,
    'icon-image': ['concat', ['get', 'operationType'], '-', ['get', 'iconKey']],
    'icon-rotate': ['get', 'heading'],
  },
};

export const shadowLayerStyle: SymbolLayer = {
  id: FlightLayerIds.flightShadowLayer,
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': ['get', 'shadowOffset'],
    'icon-size': 0.7,
    'icon-image': ['concat', 'shadow-', ['get', 'iconKey']],
    'icon-rotate': ['get', 'heading'],
  },
  paint: {
    'icon-opacity': 0.5,
  },
};
