/*
 * app authentiction
 */

import React, { useState } from 'react';
import { createClient } from 'src/client';
// components
import { Authenticated } from 'src/app/components';
import { StartScreen } from 'src/app/components';
import { PageSkeleton } from 'src/components';
// utils
import {
  initiateAuthCodeflow,
  defineAuthState,
  getToken,
  processAuthResponse,
} from 'src/app/functions/core';
import { removeAuth } from 'src/app/functions/storage';

// define app's authentication state as soon as index loads
const { state, query } = defineAuthState();

export const AuthenticationContainer = () => {
  const [isAuthFailed, authFailed] = useState<boolean>(false);
  const [authData, updateAuthData] = useState<null | {
    authorization: string;
    refreshToken: string;
    refreshTokenExpiry: number;
  }>(null);

  if (isAuthFailed) {
    removeAuth();
    return <StartScreen clickEvent={initiateAuthCodeflow} error={'failed'} />;
  } else if (authData) {
    return <Authenticated client={createClient(authData)} token={authData.authorization} />;
  } else {
    if (state === 'start') {
      const { text, error, displayButton } = query;
      return (
        <StartScreen
          clickEvent={initiateAuthCodeflow}
          text={text}
          error={error}
          displayButton={displayButton}
        />
      );
    } else if (state === 'code') {
      getToken(query)
        .then(response => {
          updateAuthData(processAuthResponse(response));
        })
        .catch(() => {
          authFailed(true);
        });
    } else if (state === 'valid') {
      // page is refreshed and the access token is still valid
      const client = createClient({
        authorization: query.authorization,
        refreshToken: null,
        refreshTokenExpiry: 0,
      });
      return <Authenticated client={client} token={query.authorization} />;
    } else if (state === 'signout') {
      if (query.removeAuth) {
        removeAuth();
      }
      return <StartScreen clickEvent={initiateAuthCodeflow} text={query.text} />;
    }
  }

  return <PageSkeleton />;
};
