import { useEffect } from 'react';
import { History, Location } from 'history';
// functions
import { updateDateRangeFilter } from 'src/app/functions/dateRange';
import { isValidDateRangeQueryString, getDateRangeParams } from 'src/app/functions/queryString';
// stores
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// actions
import { onRouteChange } from 'src/app/actions';
// constants
import {
  OPERATIONS,
  INFRINGEMENTS,
  NOISEEVENTS,
  COMPLAINTS,
  SETTINGS,
  NOISE_MODELING,
  REALTIME,
} from 'src/constants';

/**
 * Custom Hook function:
 * A custom Hook is a JavaScript function whose name starts with ”use” and that may call other Hooks.
 * The purpose of useRouteHistoryTracker Hook is to subscribe us to route changes
 */
export const useRouteHistoryTracker = (history: History) => {
  useEffect(() => {
    onRouteChange();
    // listen for query-string changes and re-apply the date range params
    const unlisten = history.listen(({ pathname, search }: Location) => {
      if (isValidDateRangeQueryString(search)) {
        const { from, to } = getDateRangeParams(search);
        const { from: fromInStore, to: toInStore } = dateRangeStore.getDatesStrings();
        if (from && to && (from !== fromInStore || to !== toInStore)) {
          // update the date range again to reflect URL's query string params
          updateDateRangeFilter(
            {
              from: from || '',
              to: to || '',
            },
            getRoute(pathname)
          );
        }
      }
    });
    return () => {
      // remove event listener after unmount
      unlisten();
    };
  }, []);
};

export const getRoute = (pathname: string) => {
  const pathNameArray = pathname.split('/');
  return pathNameArray && pathNameArray.length > -2 ? pathNameArray[2] : '';
};

// add url-paths for the required routes that need the summary/details page (if not available)
export const additionalRequiredRoutes = (availableRoutes: string[]) => {
  const routes: string[] = availableRoutes;

  if (availableRoutes.includes(OPERATIONS) && !availableRoutes.includes(`${OPERATIONS}/{id}`)) {
    routes.push(`${OPERATIONS}/{id}`);
  }
  if (
    (availableRoutes.includes(INFRINGEMENTS) ||
      availableRoutes.includes(`${INFRINGEMENTS}-candidates`) ||
      availableRoutes.includes(`${INFRINGEMENTS}-atc`)) &&
    !availableRoutes.includes(`${INFRINGEMENTS}/{id}`)
  ) {
    routes.push(`${INFRINGEMENTS}/{id}`);
  }
  if (availableRoutes.includes(NOISEEVENTS) && !availableRoutes.includes(`${NOISEEVENTS}/{id}`)) {
    routes.push(`${NOISEEVENTS}/{id}`);
  }
  if (availableRoutes.includes(COMPLAINTS) && !availableRoutes.includes(`${COMPLAINTS}/create`)) {
    routes.push(`${COMPLAINTS}/create`);
  }

  if (
    availableRoutes.includes(COMPLAINTS) &&
    !availableRoutes.includes(`${COMPLAINTS}/{id}/flight`)
  ) {
    routes.push(`${COMPLAINTS}/{id}/flight`);
  }

  if (availableRoutes.includes(COMPLAINTS) && !availableRoutes.includes(`${COMPLAINTS}/{id}`)) {
    routes.push(`${COMPLAINTS}/{id}`);
  }

  if (
    availableRoutes.includes(NOISE_MODELING) &&
    !availableRoutes.includes(`${NOISE_MODELING}/{id}`)
  ) {
    routes.push(`${NOISE_MODELING}/{id}`);
  }

  if (availableRoutes.includes(REALTIME)) {
    availableRoutes.push(REALTIME);
  }

  // Routes for settings pages
  if (availableRoutes.includes(SETTINGS)) {
    const subpaths = [
      'general',
      'infringement-rules',
      'infringement-rules/{id}',
      'spatial-features',
      'spatial-features/{id}',
      'changelog',
      'carbon',
      'carbon/aircraft-mappings',
      'carbon/fleet-mix',
      'carbon/time-modes',
      'carbon/load-factors',
      'carbon/fuel-types',
      'modeling/contours',
      'modeling/aircraft-assignments/{id}',
      'modeling/aircraft-assignments',
      'nmt-management/{id}',
      'nmt-management',
      'taxi-path',
      'modeling',
    ];
    for (const subpath of subpaths) {
      const fullSubPath = `${SETTINGS}/${subpath}`;
      if (!availableRoutes.includes(fullSubPath)) {
        routes.push(fullSubPath);
      }
    }
  }
  return routes;
};
