import { useConfigSelectors } from 'src/app/reducers';

// Original config selector is not type.... and a pain to fix currently, this wrapper is a workaround
export const useMapConfigsWrapper = () => {
  const configSelectors = useConfigSelectors();
  const {
    map: { centre, zoom, mapAccessToken, maxZoom, minZoom, sitePrefix },
  } = configSelectors.getConfig() as {
    map: {
      centre: { latitude: number; longitude: number; altitude: number };
      zoom: number;
      mapAccessToken: string;
      maxZoom: number;
      minZoom: number;
      sitePrefix: string;
    };
  };
  return { centre, zoom, mapAccessToken, maxZoom, minZoom, sitePrefix };
};
