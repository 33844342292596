import React from 'react';

import { NavigationControl } from 'react-map-gl';
import { useLanguageSelectors } from 'src/app/reducers';
import { useResetMapView } from 'src/@realtime/hooks';
import { MapActionType, useMapContext } from 'src/@realtime/contexts/map';
import { MapControlButton, MapSettingsModal } from 'src/@realtime/components';

interface MapControlsProps {
  showCompass?: boolean;
  resetView?: boolean;
  mapSettings?: boolean;
}

export const MapControls: React.FC<MapControlsProps> = ({
  showCompass = false,
  resetView = false,
  mapSettings = false,
}) => {
  const { dispatch } = useMapContext();
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsString },
      labels: { backToCenter: backToCenterString },
    },
  } = languageSelectors.getLanguage();
  const resetMap = useResetMapView();

  const handleOpenSettings = () => {
    dispatch({ type: MapActionType.TOGGLE_SETTINGS });
  };

  return (
    <div className="navigation-control">
      <NavigationControl style={{ position: 'static' }} showCompass={showCompass} />
      {resetView && (
        <MapControlButton
          title={backToCenterString}
          onClick={resetMap}
          className="navigation-control-home"
          iconName="ic-ui-home"
          position="top-right"
        />
      )}
      {mapSettings && (
        <>
          <MapSettingsModal />
          <MapControlButton
            title={mapSettingsString}
            onClick={handleOpenSettings}
            className="mapboxgl-ctrl-settings"
            iconName="ic-ui-map-layer"
            position="top-right"
          />
        </>
      )}
    </div>
  );
};
