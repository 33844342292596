// SignalR constants
export const STREAM_METHOD_NAMES = {
  GET_TRACK_POINTS: 'GetTrackPoints',
  GET_NOISE: 'GetNoise',
};

export const STREAM_HUB_NAMES = {
  REAL_TIME_FLIGHT: 'realtimeflight',
};

// Playback constants
export const PLAYBACK_HISTORY_MINUTES = 5;
export const PLAYBACK_HISTORY_SECONDS = PLAYBACK_HISTORY_MINUTES * 60;
export const PLAYBACK_TICK_INTERVAL_MS = 1000;
export const PLAYBACK_MAX_HISTORY = 120;

// Map constants
export const MapStyles = {
  Basic: {
    url: 'mapbox://styles/mapbox/light-v11',
    name: 'Basic',
    img: '/map/mapstyle_basic.png',
  },
  Street: {
    url: 'mapbox://styles/mapbox/streets-v12',
    name: 'Street',
    img: '/map/mapstyle_street.png',
  },
  Satellite: {
    url: 'mapbox://styles/mapbox/satellite-v9',
    name: 'Satellite',
    img: '/map/mapstyle_satellite.png',
  },
};

export const SHADOW_ICON_OFFSETS = {
  0: [10.0, 10.0],
  15: [12.25, 7.07],
  30: [13.66, 3.66],
  45: [14.14, 0.0],
  60: [13.66, -3.66],
  75: [12.25, -7.07],
  90: [10.0, -10.0],
  105: [7.07, -12.25],
  120: [3.66, -13.66],
  135: [0.0, -14.14],
  150: [-3.66, -13.66],
  165: [-7.07, -12.25],
  180: [-10.0, -10.0],
  195: [-12.25, -7.07],
  210: [-13.66, -3.66],
  225: [-14.14, 0.0],
  240: [-13.66, 3.66],
  255: [-12.25, 7.07],
  270: [-10.0, 10.0],
  285: [-7.07, 12.25],
  300: [-3.66, 13.66],
  315: [0.0, 14.14],
  330: [3.66, 13.66],
  345: [7.07, 12.25],
  360: [10.0, 10.0],
};
