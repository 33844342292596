// Playback status types
export enum PlaybackStatus {
  Playing = 'playing',
  Paused = 'paused',
  Buffering = 'buffering',
}

// Playback state structure
export interface PlaybackState {
  timestamps: number[]; // Array of received timestamps
  playbackStatus: PlaybackStatus; // Current playback status
  currentTimestamp: number | null; // Currently playing timestamp
}

// Action types
export enum PlaybackActionType {
  ADD_TIMESTAMPS = 'PUSH_TIMESTAMPS',
  SET_PLAYBACK_STATUS = 'SET_PLAYBACK_STATUS',
  SET_CURRENT_TIMESTAMP = 'SET_CURRENT_TIMESTAMP',
  ADVANCE_TIMESTAMP = 'ADVANCE_TIMESTAMP',
}

export type PlaybackAction =
  | { type: PlaybackActionType.ADD_TIMESTAMPS; payload: number }
  | { type: PlaybackActionType.SET_PLAYBACK_STATUS; payload: PlaybackStatus }
  | { type: PlaybackActionType.SET_CURRENT_TIMESTAMP; payload: number | null }
  | { type: PlaybackActionType.ADVANCE_TIMESTAMP };
