import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';
import { PlaybackAction, PlaybackActionType, PlaybackState, PlaybackStatus } from './types';

export const playbackReducer = (state: PlaybackState, action: PlaybackAction): PlaybackState => {
  switch (action.type) {
    case PlaybackActionType.ADD_TIMESTAMPS:
      const newTimestamp = action.payload;

      if (state.timestamps.includes(newTimestamp)) {
        return state;
      }

      const closest = state.timestamps
        .filter(timestamp => timestamp < newTimestamp)
        .sort((a, b) => b - a)[0];

      if (closest) {
        const missingTimestamps: number[] = [];
        for (
          let ts = closest + PLAYBACK_TICK_INTERVAL_MS;
          ts < newTimestamp;
          ts += PLAYBACK_TICK_INTERVAL_MS
        ) {
          missingTimestamps.push(ts);
        }

        return {
          ...state,
          timestamps: [...state.timestamps, ...missingTimestamps, newTimestamp].sort(
            (a, b) => a - b
          ),
        };
      } else {
        return {
          ...state,
          timestamps: [...state.timestamps, newTimestamp].sort((a, b) => a - b),
        };
      }

    case PlaybackActionType.SET_PLAYBACK_STATUS:
      return {
        ...state,
        playbackStatus: action.payload,
      };

    case PlaybackActionType.SET_CURRENT_TIMESTAMP:
      return {
        ...state,
        currentTimestamp: action.payload,
      };

    case PlaybackActionType.ADVANCE_TIMESTAMP:
      const currentIndex =
        state.currentTimestamp !== null ? state.timestamps.indexOf(state.currentTimestamp) : -1;

      // Stay a bit behind the last timestamp to avoid running out of data
      if (currentIndex < state.timestamps.length - 10) {
        const nextTimestamp = state.timestamps[currentIndex + 1];
        return {
          ...state,
          currentTimestamp: nextTimestamp,
        };
      } else {
        return {
          ...state,
          playbackStatus: PlaybackStatus.Paused,
        };
      }

    default:
      return state;
  }
};
