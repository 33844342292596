import { useCallback, useEffect, useRef } from 'react';
import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';

import { PlaybackActionType, PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';

export const useTimeProgression = () => {
  const {
    state: { playbackStatus },
    dispatch,
  } = usePlaybackContext();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const advanceTimestamp = useCallback(() => {
    dispatch({ type: PlaybackActionType.ADVANCE_TIMESTAMP });
  }, [dispatch]);

  useEffect(() => {
    if (playbackStatus === PlaybackStatus.Playing) {
      // Start progression every second
      intervalRef.current = setInterval(advanceTimestamp, PLAYBACK_TICK_INTERVAL_MS);
    } else if (playbackStatus === PlaybackStatus.Paused && intervalRef.current) {
      // Stop progression if paused
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // Clean up interval on unmount or status change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [playbackStatus, advanceTimestamp]);

  return null;
};
