import React, { useEffect, useState } from 'react';

import { MapActionType, MapStyleType, useMapContext } from 'src/@realtime/contexts/map';
import { useLanguageSelectors } from 'src/app/reducers';
import { MapStyles } from 'src/@realtime/constants';
import { MapLayerList, MapLayerItem, MapLayerItemImg, MapLayerItemLabel } from './style';
import { MapSettingsHeader, MapSettingsItem } from '../../style';

const BackgroundBlock = ({
  style,
  isSelected,
  setStyle,
}: {
  style: MapStyleType;
  isSelected: boolean;
  setStyle: React.Dispatch<React.SetStateAction<MapStyleType>>;
}) => {
  const { img, name } = style;

  return (
    <MapLayerItem className="backgrounds-panel_option" onClick={() => setStyle(style)}>
      <MapLayerItemImg className="backgrounds-panel_image" selected={isSelected}>
        <img src={img} alt={name} />
      </MapLayerItemImg>
      <MapLayerItemLabel className="backgrounds-panel_label">{name}</MapLayerItemLabel>
    </MapLayerItem>
  );
};

export const BackgroundSettings: React.FC = ({}) => {
  const {
    state: { selectedMapStyle },
    dispatch,
  } = useMapContext();
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { background: backgroundTitle },
    },
  } = languageSelectors.getLanguage();

  const [selectedStyle, setSelectedStyle] = useState<MapStyleType>(selectedMapStyle);

  useEffect(() => {
    if (selectedStyle.name !== selectedMapStyle.name) {
      dispatch({ type: MapActionType.UPDATE_STYLE, payload: selectedStyle });
    }
  }, [selectedStyle]);

  return (
    <MapSettingsItem className="backgrounds-panel">
      <MapSettingsHeader className="backgrounds-panel_title">{backgroundTitle}</MapSettingsHeader>
      <MapLayerList>
        {Object.keys(MapStyles).map(key => {
          const style = MapStyles[key] as MapStyleType;
          return (
            <BackgroundBlock
              key={key}
              style={style}
              isSelected={selectedStyle.name === style.name}
              setStyle={setSelectedStyle}
            />
          );
        })}
      </MapLayerList>
    </MapSettingsItem>
  );
};
