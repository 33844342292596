import styled from 'styled-components/macro';

// Colors
// import styles from 'src/styles/vars.module.scss';

export const MapLayerList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MapLayerItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
`;

export const MapLayerItemImg = styled.div<{ selected: boolean }>`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;

  border: ${({ selected }) => (selected ? '3px solid #0b6bf2' : 'none')};

  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
`;

export const MapLayerItemLabel = styled.span`
  font-family: 'Inter var', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #2e384d;
  margin-left: 0.75rem;
`;
