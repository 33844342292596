import { useEffect, useRef } from 'react';
import { StreamedFlightPoint, useAuthStoreContext } from 'src/@realtime/contexts';
import {
  useLoadLiveFlightData,
  useSignalrConnection,
  useSignalrSubscription,
} from 'src/@realtime/hooks';
import {
  PLAYBACK_MAX_HISTORY,
  STREAM_HUB_NAMES,
  STREAM_METHOD_NAMES,
} from 'src/@realtime/constants';
import { getDeployedProductId } from 'src/utils';
import { StreamedFlightParameters, StreamedFlightPoints } from 'src/@realtime/types';
import { useConfigSelectors } from 'src/app/reducers';
import { unstable_batchedUpdates } from 'react-dom';

const useBatchUpdates = () => {
  const queue = useRef<Record<string, StreamedFlightPoint>>({});
  const loadPointData = useLoadLiveFlightData();
  const dispatchInterval = useRef(0);

  useEffect(() => {
    dispatchInterval.current = window.setInterval(() => {
      if (!Object.keys(queue.current).length) {
        return;
      }
      unstable_batchedUpdates(() => {
        Object.entries(queue.current).forEach(([key, value]) => {
          loadPointData(value);
        });
      });
    }, 1000);
    return () => {
      window.clearInterval(dispatchInterval.current);
    };
  }, []);

  return queue;
};

export const useLoadLiveData = () => {
  const q2 = useBatchUpdates();

  const { token } = useAuthStoreContext();
  const {
    AviationRealtimeApi: { EndpointUrl },
  } = useConfigSelectors().getConfig();

  const { connection } = useSignalrConnection(
    `${EndpointUrl}${getDeployedProductId()}/${STREAM_HUB_NAMES.REAL_TIME_FLIGHT}`,
    token
  );

  // Only pass `connection` if it exists to avoid calling the hook unnecessarily.
  useSignalrSubscription<StreamedFlightPoints, StreamedFlightParameters>({
    connection: connection || null, // Provide `null` or a fallback if `connection` is not ready
    subscriptionParameters: connection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_TRACK_POINTS,
              { historySeconds: PLAYBACK_MAX_HISTORY },
            ],
            subscriber: {
              next: (data: StreamedFlightPoints) => {
                const { trackId } = data.point as StreamedFlightPoint;
                if (!q2.current[trackId]) {
                  q2.current[trackId] = data.point;
                } else {
                  q2.current[trackId] = {
                    ...q2.current[trackId],
                    ...data.point,
                  };
                }
              },
              complete: () => {},
              error: e => {
                console.error(e);
              },
            },
          },
        ]
      : [],
  });
};
