import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { flightReducer } from './reducer';
import { FlightDataAction, FlightDataState } from './types';

const initialState: FlightDataState = {
  flightInfo: {},
  positions: {},
  selectedTracks: [],
};

const FlightDataContext = createContext<{
  state: FlightDataState;
  dispatch: Dispatch<FlightDataAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const FlightDataProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(flightReducer, initialState);

  return (
    <FlightDataContext.Provider value={{ state, dispatch }}>{children}</FlightDataContext.Provider>
  );
};

export const useFlightDataContext = (): {
  state: FlightDataState;
  dispatch: Dispatch<FlightDataAction>;
} => {
  const context = useContext(FlightDataContext);
  if (!context) {
    throw new Error('useFlightData must be used within a FlightDataProvider');
  }
  return context;
};
