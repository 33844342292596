// Constants
import { EXCEL_EXPORT, WORD_EXPORT, KML_EXPORT, INM_EXPORT, CSV_EXPORT } from 'src/constants';

export const exportTypes = {
  EXCEL: {
    type: EXCEL_EXPORT,
    iconName: 'ic-ui-report',
    iconColor: '#36b37e',
    format: 'Excel',
    useCustomBody: false,
  },
  WORD: {
    type: WORD_EXPORT,
    iconName: 'ic-ui-report',
    iconColor: '#2684ff',
    format: 'Word',
    useCustomBody: false,
  },
  KML: {
    type: KML_EXPORT,
    iconName: 'ic-ui-report',
    iconColor: '#00b8d9',
    format: 'KML',
    useCustomBody: false,
  },
  INM: {
    type: INM_EXPORT,
    iconName: 'ic-ui-report',
    iconColor: '#e88354',
    format: 'Inm',
    useCustomBody: false,
  },
  CSV: {
    type: CSV_EXPORT,
    iconName: 'ic-ui-report',
    iconColor: '#36b37e',
    format: 'csv',
    useCustomBody: true,
  },
};
