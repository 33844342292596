import React from 'react';
import {
  PlaybackActionType,
  PlaybackStatus,
  useFlightDataContext,
  usePlaybackContext,
} from 'src/@realtime/contexts';

export const DebugPanel = () => {
  const {
    state: { flightInfo },
  } = useFlightDataContext();
  const {
    state: { timestamps, playbackStatus, currentTimestamp },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  const handlePlaybackToggle = () => {
    if (playbackStatus === PlaybackStatus.Buffering) {
      return;
    }
    playbackDispatch({
      type: PlaybackActionType.SET_PLAYBACK_STATUS,
      payload:
        playbackStatus === PlaybackStatus.Playing ? PlaybackStatus.Paused : PlaybackStatus.Playing,
    });
  };

  const handleSliderChange = e => {
    const newValue = parseInt(e.target.value, 10); // Parse the slider value as an integer
    playbackDispatch({
      type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
      payload: newValue,
    });
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        float: 'left',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        padding: '10px',
      }}>
      <p>Number of flights loaded: {Object.keys(flightInfo).length}</p>
      <p>Playback Status: {playbackStatus}</p>
      <p>Current Timestamp: {new Date(currentTimestamp).toLocaleString()}</p>
      <p>
        First Timestamp: {timestamps.length > 0 ? new Date(timestamps[0]).toLocaleString() : 'N/A'}
      </p>
      <p>
        Last Timestamp:
        {timestamps.length > 0
          ? new Date(timestamps[timestamps.length - 1]).toLocaleString()
          : 'N/A'}
      </p>
      <p>
        Time range:
        {timestamps.length > 0
          ? `${((timestamps[timestamps.length - 1] - timestamps[0]) / 1000).toFixed(2)} seconds`
          : 'N/A'}
      </p>
      <div>
        <button
          disabled={playbackStatus === PlaybackStatus.Buffering}
          onClick={handlePlaybackToggle}>
          Toggle playback
        </button>
      </div>
      <div style={{ padding: '5px 0' }}>
        <input
          style={{ width: '100%' }}
          type="range"
          min={timestamps[0]}
          max={timestamps[timestamps.length - 1]}
          value={currentTimestamp}
          step={1000}
          onChange={handleSliderChange}
        />
      </div>
    </div>
  );
};
