import { useCallback, useEffect, useRef } from 'react';
import { useMap } from 'react-map-gl';
import { useMapContext } from 'src/@realtime/contexts/map';

interface LoadImageParams {
  url: string; // URL of the image to load.
  key: string; // Unique key for the image in the map.
}

export const useLoadMapImage = () => {
  const { current: map } = useMap();
  const {
    state: { selectedMapStyle },
  } = useMapContext();
  const imageParamsRef = useRef<LoadImageParams[]>([]);
  const prevMapStyleRef = useRef<string | null>(null);
  const isStyleLoadedRef = useRef(false);
  const debounceTimeoutRef = useRef<number | null>(null);

  const loadImage = useCallback(
    async (images: LoadImageParams[]) => {
      if (!map) {
        console.warn('Map instance not available');
        return;
      }

      imageParamsRef.current = images;

      const loadImagePromises = images.map(
        ({ url, key }) =>
          new Promise<void>((resolve, reject) => {
            if (map.hasImage(key)) {
              // console.warn(`Image with key "${key}" already exists. Skipping load.`);
              resolve();
              return;
            }

            map.loadImage(url, (error, image) => {
              if (error) {
                console.error(`Failed to load image at ${url}:`, error);
                reject(error);
                return;
              }
              if (image) {
                map.addImage(key, image);
                resolve();
              } else {
                reject(new Error(`Invalid image at ${url}`));
              }
            });
          })
      );

      try {
        await Promise.all(loadImagePromises);
      } catch (error) {
        console.error('Error loading one or more images:', error);
      }
    },
    [map]
  );

  useEffect(() => {
    if (!map || !selectedMapStyle) {
      return;
    }

    const handleStyleData = () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = window.setTimeout(() => {
        if (!isStyleLoadedRef.current) {
          isStyleLoadedRef.current = true;

          if (imageParamsRef.current.length > 0) {
            void loadImage(imageParamsRef.current);
          }
        }
      }, 500); // Delay of 500ms
    };

    if (prevMapStyleRef.current !== selectedMapStyle.url) {
      prevMapStyleRef.current = selectedMapStyle.url;
      isStyleLoadedRef.current = false;

      map.on('styledata', handleStyleData);
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      map.off('styledata', handleStyleData);
    };
  }, [map, selectedMapStyle, loadImage]);

  return loadImage;
};
