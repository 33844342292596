import { MapStyles } from 'src/@realtime/constants';

export type MapStyle = keyof typeof MapStyles;

export enum MapActionType {
  UPDATE_STYLE = 'UPDATE_STYLE',
  TOGGLE_SETTINGS = 'TOGGLE_SETTINGS',
  CLOSE_SETTINGS = 'CLOSE_SETTINGS',
  SAVE_SETTINGS = 'SAVE_SETTINGS',
  RESET_TO_DEFAULT = 'RESET_TO_DEFAULT',
  ADD_CLICK_EVENT = 'ADD_CLICK_EVENT',
  REMOVE_CLICK_EVENT = 'REMOVE_CLICK_EVENT',
}

export interface MapEventConfig {
  id: string;
  event: (event: mapboxgl.MapLayerMouseEvent) => void;
}

export type MapStyleType = typeof MapStyles[keyof typeof MapStyles];
export interface MapState {
  selectedMapStyle: MapStyleType;
  isMapSettingsOpen: boolean;
  clickEvents: MapEventConfig[];
  stateToUpdate: {
    selectedMapStyle: MapStyleType;
  } | null;
}

// Action types
export type MapAction =
  | {
      type: MapActionType.UPDATE_STYLE;
      payload: MapStyleType;
    }
  | {
      type: MapActionType.TOGGLE_SETTINGS;
    }
  | {
      type: MapActionType.CLOSE_SETTINGS;
    }
  | {
      type: MapActionType.SAVE_SETTINGS;
    }
  | {
      type: MapActionType.RESET_TO_DEFAULT;
    }
  | {
      type: MapActionType.ADD_CLICK_EVENT;
      payload: MapEventConfig;
    }
  | {
      type: MapActionType.REMOVE_CLICK_EVENT;
      payload: string;
    };
