import { MapState, MapAction, MapActionType } from './types';
import { initialState } from './context';

export const mapReducer = (state: MapState, action: MapAction): MapState => {
  switch (action.type) {
    case MapActionType.UPDATE_STYLE:
      return {
        ...state,
        stateToUpdate: {
          selectedMapStyle: action.payload,
        },
      };
    case MapActionType.TOGGLE_SETTINGS:
      return {
        ...state,
        isMapSettingsOpen: !state.isMapSettingsOpen,
      };
    case MapActionType.CLOSE_SETTINGS:
      return {
        ...state,
        isMapSettingsOpen: false,
      };
    case MapActionType.SAVE_SETTINGS:
      return {
        ...state,
        isMapSettingsOpen: false,
        selectedMapStyle: state.stateToUpdate.selectedMapStyle,
        stateToUpdate: null,
      };
    case MapActionType.RESET_TO_DEFAULT:
      return {
        ...state,
        isMapSettingsOpen: false,
        selectedMapStyle: initialState.selectedMapStyle,
      };
    case MapActionType.ADD_CLICK_EVENT:
      return {
        ...state,
        clickEvents: [...state.clickEvents, action.payload],
      };
    case MapActionType.REMOVE_CLICK_EVENT:
      return {
        ...state,
        clickEvents: state.clickEvents.filter(event => event.id !== action.payload),
      };
    default:
      return state;
  }
};
