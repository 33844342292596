import { useMap } from 'react-map-gl';
import { useCallback } from 'react';
import { useMapConfigsWrapper } from './useMapConfigsWrapper';

export const useResetMapView = () => {
  const { current: map } = useMap();
  const {
    centre: { latitude, longitude },
    zoom,
  } = useMapConfigsWrapper();

  const resetMapView = useCallback(() => {
    if (!map) {
      console.warn('Map instance not available');
      return;
    }
    map.flyTo({
      center: [longitude, latitude],
      zoom,
    });
  }, [map, latitude, longitude, zoom]);

  return resetMapView;
};
