import GLMap, { MapProps as GLMapProps } from 'react-map-gl';

import React from 'react';
import { useMapContext } from 'src/@realtime/contexts/map';

interface MapProps extends Omit<GLMapProps, 'mapboxAccessToken' | 'initialViewState' | 'mapStyle'> {
  latitude: number;
  longitude: number;
  zoom: number;
  apiKey: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Map: React.FC<MapProps> = ({
  latitude,
  longitude,
  zoom,
  apiKey,
  style,
  children,
  ...rest
}) => {
  const {
    state: { selectedMapStyle, clickEvents },
  } = useMapContext();

  const handleMapClick = (e: mapboxgl.MapLayerMouseEvent) => {
    if (!clickEvents.length) {
      return;
    }
    clickEvents.forEach(event => {
      event.event(e);
    });
  };

  return (
    <GLMap
      onClick={e => handleMapClick(e as mapboxgl.MapLayerMouseEvent)}
      mapboxAccessToken={apiKey}
      initialViewState={{
        longitude,
        latitude,
        zoom,
      }}
      style={style}
      mapStyle={selectedMapStyle.url}
      {...rest}>
      {children}
    </GLMap>
  );
};
