import React, { useEffect, useRef } from 'react';
import { ControlPosition, useMap } from 'react-map-gl';
import { Icons } from '@ems/client-design-system';
import ReactDOM from 'react-dom';

interface MapControlButtonProps {
  title: string;
  onClick: () => void;
  iconName: string;
  className?: string;
  position?: ControlPosition;
}

export const MapControlButton: React.FC<MapControlButtonProps> = ({
  title,
  onClick,
  iconName,
  className = '',
  position = 'top-right',
}) => {
  const controlContainerRef = useRef<HTMLDivElement | null>(null);
  const { current: map } = useMap(); // Assumes you're using `useMap` from `react-map-gl`

  useEffect(() => {
    if (!map) {
      return;
    }

    const controlDiv = document.createElement('div');
    controlDiv.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    // Attach ref for cleanup
    controlContainerRef.current = controlDiv;

    // Create button using React
    const button = (
      <button
        type="button"
        aria-label={title}
        aria-disabled="false"
        title={title}
        className={className}
        onClick={onClick}>
        <Icons size={20} iconName={iconName} />
      </button>
    );

    ReactDOM.render(button, controlDiv);

    // Add control to Mapbox
    map.addControl(
      {
        onAdd: () => controlDiv,
        onRemove: () => {
          ReactDOM.unmountComponentAtNode(controlDiv);
          controlDiv.parentNode?.removeChild(controlDiv);
        },
      },
      position
    );

    return () => {
      ReactDOM.unmountComponentAtNode(controlDiv);
      controlDiv.parentNode?.removeChild(controlDiv);
      controlContainerRef.current = null;
    };
  }, [map, title, onClick, iconName, className, position]);

  return null;
};
