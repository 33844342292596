import React, { useMemo } from 'react';
import { Source, Layer } from 'react-map-gl';

import { flightTailLayerStyle } from './styles';

import { FlightTailLayerIds } from './constants';
import { useFlightDataContext } from 'src/@realtime/contexts';

import { Feature, FeatureCollection } from 'geojson';

const useBuildFlightTails = () => {
  const {
    state: { positions, flightInfo, selectedTracks },
  } = useFlightDataContext();

  return useMemo(() => {
    const features: Feature[] = selectedTracks
      .map(trackId => {
        const trackPositions = positions[trackId];
        if (!trackPositions) {
          return null;
        }

        const feature: Feature = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: trackPositions.map(position => [position.longitude, position.latitude]),
          },
          properties: {
            trackId,
            flightInfo: flightInfo[trackId],
            operationColor: flightInfo[trackId].operationColor,
          },
        };
        return feature;
      })
      .filter(feature => feature !== null);

    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features,
    };
    return featureCollection;
  }, [flightInfo, selectedTracks]);
};

export const FlightTailLayer: React.FC = () => {
  const flightData = useBuildFlightTails();

  return (
    <Source id={FlightTailLayerIds.flightTailLayer} type="geojson" data={flightData}>
      <Layer {...flightTailLayerStyle} />
    </Source>
  );
};
