import React, { CSSProperties } from 'react';
import { Fab, Action, Icons } from '@ems/client-design-system';
import { ThemeProvider } from 'styled-components/macro';
import { useLanguageSelectors } from 'src/app/reducers';

const getPositionForView = (currentView: string): CSSProperties => {
  switch (currentView) {
    case 'FULL_SCREEN_MAP_LAYOUT':
      return {
        position: 'absolute',
        bottom: '-0.75rem',
        right: '8rem',
      };
    case 'FULL_SCREEN_GRID_LAYOUT':
      return {
        top: '-1rem',
        right: '1rem',
      };
    case 'SPLIT_PORTRAIT_LAYOUT':
      return {
        position: 'absolute',
        bottom: 'calc(100vh - 31rem)',
        right: '8rem',
      };
    case 'SPLIT_LANDSCAPE_LAYOUT':
    default:
      return {
        // Declared to indicate that the labels will be rendered
        // to the _left_ of the buttons, i.e. the opposite of the padding.
        right: undefined,
      };
  }
};

const labelTheme = {
  color: '#2e384d',
  background: '#ffffff',
  fontSize: '13px',
};

const theme = {
  action: {
    color: '#8897a2',
    fontSize: '16px',
    size: '40px',
    background: '#ffffff',
    label: labelTheme,
  },
  main: {
    color: '#ffffff',
    fontSize: '18px',
    size: '42px',
    background: '#0b6bf2',
    label: labelTheme,
  },
};

interface IViewSwitcherProps {
  currentView: string;
  onSwitch: (newView: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ViewSwitcher = ({ currentView, onSwitch }: IViewSwitcherProps) => {
  const position: CSSProperties = getPositionForView(currentView);
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        landscape: landscapeLabel,
        portrait: portraitLabel,
        fullTable: fullTableLabel,
        fullMap: fullMapLabel,
      },
    },
  } = languageSelectors.getLanguage();
  return (
    <ThemeProvider theme={theme}>
      <Fab
        icon={<Icons iconName={'ic-add-sm'} inline size="40" />}
        alwaysShowTitle
        // @ts-ignore
        style={position}
        event={'click'}>
        <Action text={fullTableLabel} onClick={() => onSwitch('FULL_SCREEN_GRID_LAYOUT')}>
          <Icons iconName={'ic-table-view'} inline />
        </Action>
        <Action
          text={fullMapLabel}
          onClick={() => {
            window.scrollTo(0, 0);
            onSwitch('FULL_SCREEN_MAP_LAYOUT');
          }}>
          <Icons iconName={'ic-map-view'} inline />
        </Action>
        <Action text={portraitLabel} onClick={() => onSwitch('SPLIT_LANDSCAPE_LAYOUT')}>
          <Icons iconName={'ic-portrait-view'} inline />
        </Action>
        <Action text={landscapeLabel} onClick={() => onSwitch('SPLIT_PORTRAIT_LAYOUT')}>
          <Icons iconName={'ic-landscape-view'} inline />
        </Action>
      </Fab>
    </ThemeProvider>
  );
};
