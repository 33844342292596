import { FlightPosition } from 'src/@realtime/contexts';

import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';
import { OperationType } from './sprites';
import { Position } from '@turf/turf';

/**
 * Interpolates missing flight position points between two given positions.
 *
 * @param previous - The previous flight position.
 * @param current - The current flight position.
 * @returns An array of interpolated flight positions.
 */
export const interpolateMissingPoints = (
  previous: FlightPosition,
  current: FlightPosition
): FlightPosition[] => {
  const points: FlightPosition[] = [];

  // Calculate the number of intervals to fill based on PLAYBACK_TICK_INTERVAL_MS
  const startTime = previous.time;
  const endTime = current.time;
  const missingIntervals = (endTime - startTime) / PLAYBACK_TICK_INTERVAL_MS - 1;

  // Calculate the increments for latitude, longitude, and altitude
  const latIncrement = (current.latitude - previous.latitude) / (missingIntervals + 1);
  const lngIncrement = (current.longitude - previous.longitude) / (missingIntervals + 1);
  const altIncrement = (current.altitude - previous.altitude) / (missingIntervals + 1);

  // Generate interpolated points for each missing interval
  for (let i = 1; i <= missingIntervals; i++) {
    const time = startTime + i * PLAYBACK_TICK_INTERVAL_MS;
    const latitude = previous.latitude + latIncrement * i;
    const longitude = previous.longitude + lngIncrement * i;
    const altitude = previous.altitude + altIncrement * i;
    const heading = points[i - 1]
      ? calculateBearing([points[i - 1].longitude, points[i - 1].latitude], [longitude, latitude])
      : calculateBearing([previous.longitude, previous.latitude], [longitude, latitude]);
    points.push({
      time,
      latitude,
      longitude,
      altitude,
      heading,
    });
  }
  points.push({
    ...current,
    heading: calculateBearing(
      [previous.longitude, previous.latitude],
      [current.longitude, current.latitude]
    ),
  });
  return points;
};

export const getOperationType = ({
  destination,
  origin,
  siteCode,
}: {
  destination: string;
  origin: string;
  siteCode: string;
}) => {
  if (!origin || !destination) {
    return OperationType.overflight;
  }
  if (destination.toLowerCase() === siteCode.toLowerCase()) {
    return OperationType.arrival;
  }
  if (origin.toLowerCase() === siteCode.toLowerCase()) {
    return OperationType.departure;
  }
  return OperationType.overflight;
};

const toRadians = (degrees: number) => degrees * (Math.PI / 180);
const toDegrees = (radians: number) => radians * (180 / Math.PI);

/**
 * Calculates the bearing between two geographical positions.
 *
 * @param previousPosition - The previous geographical position as a tuple [longitude, latitude].
 * @param currentPosition - The current geographical position as a tuple [longitude, latitude].
 * @returns The bearing in degrees from the previous position to the current position, normalized to a range of 0-360 degrees.
 */
export const calculateBearing = (previousPosition: Position, currentPosition: Position): number => {
  const lat1 = toRadians(previousPosition[1]);
  const lon1 = toRadians(previousPosition[0]);
  const lat2 = toRadians(currentPosition[1]);
  const lon2 = toRadians(currentPosition[0]);

  const dLon = lon2 - lon1;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  const bearing = toDegrees(Math.atan2(y, x));
  return (bearing + 360) % 360; // Normalize to 0-360
};
