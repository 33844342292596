import React, { useEffect, useMemo, useState } from 'react';
import { Source, Layer } from 'react-map-gl';

import { FeatureCollection } from 'geojson';
import { SHADOW_ICON_OFFSETS } from 'src/@realtime/constants';
import { flightLayerStyle, shadowLayerStyle } from './styles';
import { useAddOnMapClickFunction } from 'src/@realtime/hooks/map/useAddMapClickEvent';
import { loadMapSprites, queryMapFeaturesById } from 'src/@realtime/utils';
import { FlightLayerIds } from './constants';
import {
  FlightDataActionType,
  useFlightDataContext,
  usePlaybackContext,
} from 'src/@realtime/contexts';
import { Feature } from 'geojson';
import { SpriteKeys } from 'src/@realtime/utils/sprites';
import { useConfigSelectors } from 'src/app/reducers';

const generateFeatures = (count: number): Feature[] => {
  const features: Feature[] = [];
  Object.keys(SpriteKeys).forEach((key, index) => {
    for (let i = 0; i < count; i++) {
      features.push({
        id: `${key}-${i}`,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [152.9 + index * 0.02, -28.1 + i * 0.02],
        },
        properties: {
          rotation: i * 15,
          operationType: 'd',
          iconKey: SpriteKeys[key as keyof typeof SpriteKeys],
          shadowOffset: (SHADOW_ICON_OFFSETS[i * 15] as [number, number]) || [0, 0],
        },
      });
    }
  });
  return features;
};

const geojson: FeatureCollection = {
  type: 'FeatureCollection',
  features: generateFeatures(25),
};

export const FlightLayer = ({ debug = false }: { debug?: boolean }): JSX.Element => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');

  const {
    state: { positions, flightInfo },
    dispatch: flightDispatch,
  } = useFlightDataContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();
  const [flightData, setFlightData] = useState<FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });

  const clickFunction = useMemo(
    () => ({
      id: 'my-map-click',
      event: ({ target, point }: mapboxgl.MapLayerMouseEvent) => {
        const features = queryMapFeaturesById(target, point, FlightLayerIds.flightLayer);
        if (features[0]) {
          flightDispatch({
            type: FlightDataActionType.SELECT_TRACK,
            payload: features[0].properties?.trackId,
          });
        }
      },
    }),
    []
  );

  useAddOnMapClickFunction(clickFunction);

  useEffect(() => {
    const featureList: Feature[] = [];
    Object.keys(positions).forEach(trackId => {
      if (positions[trackId].length < 5) {
        return;
      }

      const pos = positions[trackId].find(p => p.time === currentTimestamp);
      if (pos === undefined) {
        return;
      }
      const { longitude, latitude, altitude, heading } = pos;
      const roundedHeading = Math.round(heading / 15) * 15;
      const feature: Feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude, altitude],
        },
        properties: {
          ...flightInfo[trackId],
          trackId,
          iconKey: SpriteKeys.commercialjet,
          shadowOffset: (SHADOW_ICON_OFFSETS[roundedHeading] as [number, number]) || [0, 0],
          heading,
        },
      };
      featureList.push(feature);
    });
    setFlightData({
      type: 'FeatureCollection',
      features: featureList,
    });
  }, [currentTimestamp]);

  loadMapSprites(selectedTrackTheme);

  return (
    <Source id={FlightLayerIds.flightLayer} type="geojson" data={debug ? geojson : flightData}>
      <Layer {...shadowLayerStyle} />
      <Layer {...flightLayerStyle} />
    </Source>
  );
};
