import React from 'react';

import { Dialog, Button } from '@ems/client-design-system';
import { MapActionType, useMapContext } from 'src/@realtime/contexts/map';
import { useLanguageSelectors } from 'src/app/reducers';
import { BackgroundSettings } from './components';
import { MapSettingsWrapper, MapSettingsDialog, MapSettingsItem } from './style';

export const MapSettingsModal: React.FC = () => {
  const {
    state: { isMapSettingsOpen },
    dispatch,
  } = useMapContext();
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsString },
      labels: { resetToDefault: resetToDefaultString },
      buttons: { cancel: cancelString, save: saveString },
    },
  } = languageSelectors.getLanguage();

  const handleModalClose = () => {
    dispatch({ type: MapActionType.CLOSE_SETTINGS });
  };

  const handleModalSave = () => {
    dispatch({ type: MapActionType.SAVE_SETTINGS });
  };

  const handleModalDefault = () => {
    dispatch({ type: MapActionType.RESET_TO_DEFAULT });
  };

  return (
    <MapSettingsDialog
      isOpen={isMapSettingsOpen}
      title={mapSettingsString}
      onClose={handleModalClose}>
      <Dialog.Body>
        <MapSettingsWrapper className="map-settings_panel">
          <BackgroundSettings />
          <MapSettingsItem />
          <MapSettingsItem />
        </MapSettingsWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <div>
          <Button style="link" onClick={handleModalDefault}>
            {resetToDefaultString}
          </Button>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button onClick={handleModalClose}>{cancelString}</Button>
          <Button style="primary" onClick={handleModalSave}>
            {saveString}
          </Button>
        </div>
      </Dialog.Footer>
    </MapSettingsDialog>
  );
};
