import React, { ReactElement } from 'react';
// common components
import { Icons } from '@ems/client-design-system';
import { CSVLink } from 'react-csv';

// Types
import { IReport } from './types';

// constants
import { exportTypes } from './constants';

// constants
import {
  COMPLAINTS,
  COMPLAINT,
  OPERATIONS,
  OPERATION,
  INFRINGEMENTS,
  INFRINGEMENT,
  NOISEEVENTS,
  NOISEEVENT,
} from 'src/constants';

const buildExportObject = ({
  key,
  label,
  templateName,
  outputFilename,
  body,
}: {
  key: keyof typeof exportTypes;
  label: string;
  templateName: string;
  outputFilename: string;
  body?: ReactElement;
}): IReport => {
  const { iconName, iconColor, type, format, useCustomBody } = exportTypes[key];
  const result: IReport = {
    key,
    label,
    icon: <Icons iconName={iconName} title={label} fill={iconColor} />,
    export: {
      type,
      format,
      templateName,
      outputFilename,
    },
    useCustomBody,
  };

  if (body) {
    result.body = body;
  }

  return result;
};

export const exportOptions = ({
  source,
  translationData,
  csvExportData,
  hasExportPermission,
}: {
  source: string;
  translationData: { [key: string]: string };
  csvExportData?: { [key: string]: string | number };
  hasExportPermission: boolean;
}): IReport[] => {
  const { excel, word, kml, inm, csv } = translationData;

  // Operations
  const exportOperationsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Selection/Operations Selection Report',
    outputFilename: 'Operations Report',
  });

  const exportOperationDetailsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Letters/Operation Details',
    outputFilename: 'Operation Report',
  });

  const exportOperationToExcel = buildExportObject({
    key: 'EXCEL',
    label: excel,
    templateName: 'Selection/Operations Selection Report',
    outputFilename: 'Operations Report',
  });

  const exportOperationToKml = buildExportObject({
    key: 'KML',
    label: kml,
    templateName: '',
    outputFilename: 'Operations Report - Kml',
  });

  const exportOperationToInm = buildExportObject({
    key: 'INM',
    label: inm,
    templateName: '',
    outputFilename: 'Operations Report - Inm',
  });

  const exportToCsv = buildExportObject({
    key: 'CSV',
    label: csv,
    templateName: '',
    outputFilename: 'selected',
    body: (
      <CSVLink
        data={csvExportData || [{ error: 'Please ensure rows are selected' }]}
        filename={`${source} - ${csvExportData ? csvExportData.length : 0} selected`}
        target="_blank"
        className="bp3-menu-item bp3-popover-dismiss dropdown_item">
        <Icons iconName="ic-ui-report" title={inm} fill="#36b37e" />
        <div className="bp3-fill bp3-text-overflow-ellipsis">{csv}</div>
      </CSVLink>
    ),
  });

  // Infringements
  const exportInfringementsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Selection/Infringements Selection Report',
    outputFilename: 'Infringements Report',
  });

  const exportInfringementDetailsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Letters/Infringement Letter',
    outputFilename: 'Infringement Letter',
  });

  const exportInfringementsToExcel = buildExportObject({
    key: 'EXCEL',
    label: excel,
    templateName: 'Selection/Infringements Selection Report',
    outputFilename: 'Infringements Report',
  });

  const exportNoiseEventsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Selection/Events Selection Report',
    outputFilename: 'Events Report',
  });

  const exportNoiseEventDetailsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Letters/Event Details',
    outputFilename: 'Event Report',
  });

  const exportNoiseEventToExcel = buildExportObject({
    key: 'EXCEL',
    label: excel,
    templateName: 'Selection/Events Selection Report',
    outputFilename: 'Events Report',
  });

  const exportComplaintDetailsPage = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Letters/Inquiry Letter',
    outputFilename: 'Inquiry Letter',
  });

  const exportComplaintsToWord = buildExportObject({
    key: 'WORD',
    label: word,
    templateName: 'Selection/Inquiries Selection Report',
    outputFilename: 'Inquiries Report',
  });

  const exportComplaintToExcel = buildExportObject({
    key: 'EXCEL',
    label: excel,
    templateName: 'Selection/Inquiries Selection Report',
    outputFilename: 'Inquiries Report',
  });

  if (!hasExportPermission) {
    return [exportToCsv];
  } else {
    switch (source) {
      case OPERATIONS:
        return [
          exportOperationsToWord,
          exportOperationToExcel,
          exportToCsv,
          exportOperationToKml,
          exportOperationToInm,
        ];
      case OPERATION:
        return [exportOperationDetailsToWord, exportOperationToExcel, exportOperationToKml];
      case INFRINGEMENTS:
        return [exportInfringementsToWord, exportInfringementsToExcel, exportToCsv];
      case INFRINGEMENT:
        return [exportInfringementDetailsToWord];
      case NOISEEVENTS:
        return [exportNoiseEventsToWord, exportNoiseEventToExcel, exportToCsv];
      case NOISEEVENT:
        return [exportNoiseEventDetailsToWord];
      case COMPLAINTS:
        return [exportComplaintsToWord, exportComplaintToExcel, exportToCsv];
      case COMPLAINT:
        return [exportComplaintDetailsPage];
      default:
        return [];
    }
  }
};
